
// Bootstrap default variables and functions

@import "bootstrap/scss/functions";
@import "bootstrap/scss/mixins";

//@import "bootstrap_variables";// defaults
@import "bootstrap/scss/variables";
// domain specific css and colors are included below

$gray: $gray-500;
/**
"domainStyle" is a reference to the generated scss file inside the domain folder.
It is aliased in frontend/frontend-5.0/webpack.config.js.
It consists of bootstrap variables, global- and section-styling.
 */
@import "domainStyle"; // Ignore the warnings. Its an alias to the domain set by webpack.

// Bootstrap variables and domain specific custom colors

// Configuration
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack


// Import all custom styling for page-builder widgets
//@import "common/components/page_builder/features/styles.scss";
.background-position-top-start { background-position: top left; }
.background-position-top-center { background-position: top center; }
.background-position-top-end { background-position: top right; }
.background-position-center-start { background-position: center left; }
.background-position-center-center { background-position: center center; }
.background-position-center-end { background-position: center right; }
.background-position-bottom-start { background-position: bottom left; }
.background-position-bottom-center { background-position: bottom center; }
.background-position-bottom-end { background-position: bottom right; }

.background-repeat-no { background-repeat: no-repeat; }
.background-repeat-x { background-repeat: repeat-x; }
.background-repeat-y { background-repeat: repeat-y; }
.background-repeat-round { background-repeat: round; }
.background-repeat-space { background-repeat: space; }

.background-size-auto { background-size: auto; }
.background-size-contain { background-size: contain; }
.background-size-cover { background-size: cover; }





// Fonts
@import './fonts/fonts.scss';

@import './styling/media';

// Direct font path to absolute fontawesome webfont folder
$fa-font-path: "@fortawesome/fontawesome-pro/webfonts";
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
// import fontawesome types
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/solid';


